<template>
  <b-container>
    <b-card-actions no-actions>
      <b-row>
        <b-col>
          <div>
            <b-row
              v-if="!isChoppGiftCardConversion"
              class="method-buttons mb-1"
            >
              <b-col md="12">
                <p class="h4">
                  {{ $t('Formas de pagamento') }}
                </p>
              </b-col>
              <b-col
                v-for="item in paymentMethodsInPdv"
                :key="item.id"
                md="4"
              >
                <e-button-card
                  :id="`tab_payment-method_btn-${item.id}`"
                  icon-component="BIcon"
                  :icon="getIconByPaymentMethod[item.method]"
                  :text-title="item.name"
                  :text-shortcuts="
                    getShortcutByPaymentMethod[item.method]
                      ? [getShortcutByPaymentMethod[item.method]]
                      : []
                  "
                  @click="onShowModalValue(item)"
                />
              </b-col>
            </b-row>

            <b-row class="border-top-dark py-1">
              <b-col>
                <p class="h2 title">
                  Recebimentos
                </p>
              </b-col>
              <b-col v-if="items.length > 0">
                <p class="h3 title text-right">
                  <template v-if="paymentForm.balance > 0">
                    Saldo a pagar: <br>
                    {{ paymentForm.balance | currency }}
                  </template>
                  <template v-else>
                    Troco: <br>
                    {{ paymentForm.change | currency }}
                  </template>
                </p>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <method-line
                  v-for="(receipt, index) in paymentForm.receipts"
                  :id="`tab_payment-method_item-${index}`"
                  :key="index"
                  :receipt="receipt"
                  :value="receipt.value"
                  :is-cancelled="receipt.canceled"
                  :show-btn-canceled="isToShowCancelBtn(receipt)"
                  @click-delete="onDeleteMethodPayment(index)"
                  @click-print="stPrintReceipt({ index })"
                />
              </b-col>
            </b-row>
            <b-row v-if="hasActiveTefCardPayment">
              <b-col>
                <b-alert
                  show
                  variant="danger"
                  class="p-2"
                >
                  {{ $t('PAY_BOX_SALE.TEF.INSTRUCTION_TEF_CANCEL') }}
                </b-alert>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-card-actions>

    <modal-value
      ref="modalValue"
      :sale-uuid="currentSaleUuid"
      :balance-value="paymentForm.balance"
      :btn-confirm-busy="waitPeripheral"
      @click-confirm="onAddMethodPayment"
    />
  </b-container>
</template>

<script>
import { BContainer, BRow, BCol, BAlert } from 'bootstrap-vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { keyboardCode, paymentTypes, loadingOverlay, payBoxTypes, payBoxPayments } from '@/mixins'
import EButtonCard from '@/views/components/EButtonCard.vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'

import { isTefCard } from '@/store/utils/paybox/paybox-payments'
import MethodLine from './components/MethodLine.vue'
import ModalValue from './components/ModalValue.vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BCardActions,
    EButtonCard,
    MethodLine,
    BAlert,
    ModalValue,
  },

  mixins: [keyboardCode, paymentTypes, loadingOverlay, payBoxTypes, payBoxPayments],

  data() {
    return {
      showModalValue: true,
      waitPeripheral: false,
    }
  },

  computed: {
    ...mapState('pages/pdv', ['paymentMethodsInPdv']),
    ...mapState('pages/pdv/payBox', ['paymentForm', 'items']),
    ...mapGetters('pages/pdv/payBoxConfiguration', ['tefAgentConfigPayload']),
    ...mapGetters('pages/pdv/payBox', [
      'getSubtotalSale',
      'getTotalSale',
      'isConsignedSaleFinish',
      'isChoppGiftCardConversion',
      'currentSaleUuid',
      'hasActiveTefCardPayment',
    ]),

    getIconByPaymentMethod() {
      return {
        None: 'currency-dollar',
        CreditCard: 'credit-card',
        DebitCard: 'credit-card',
        Pix: 'x-diamond-fill',
        Cash: 'cash',
      }
    },

    getShortcutByPaymentMethod() {
      return {
        None: undefined,
        CreditCard: this.keyCode.f2,
        DebitCard: this.keyCode.f3,
        Pix: this.keyCode.f4,
        Cash: this.keyCode.f1,
      }
    },
  },

  methods: {
    ...mapActions('pages/pdv/payBox', {
      stAddReceipt: 'addReceipt',
      stRemoveReceipt: 'removeReceipt',
      stPrintReceipt: 'printReceipt',
      stValidatePayBoxActionIsBlocked: 'validatePayBoxActionIsBlocked',
    }),

    async onShowModalValue(paymentMethod) {
      const isBlocked = await this.stValidatePayBoxActionIsBlocked({
        action: this.uiPayBoxActionsEnum.PAYMENT_ADD,
      })
      if (isBlocked) {
        this.showWarning({ message: this.$t('Ação bloqueada no momento.') })
        return
      }

      const error = { message: '' }

      if (this.getTotalSale <= 0) {
        error.message = 'Total da venda está zerado'
      } else if (this.paymentForm.balance === 0) {
        error.message = 'Saldo a pagar está zerado'
      }

      if (error.message) {
        this.showWarning({ message: error.message })
        return
      }

      this.$refs.modalValue.showModal(paymentMethod)
    },

    isToShowCancelBtn(item) {
      if (item.id) return false
      return true
    },

    async onAddMethodPayment(value) {
      try {
        this.waitPeripheral = true

        this.stAddReceipt(value)
        await this.stPrintReceipt({ index: -1 }) // print last

        this.$refs.modalValue.hideModal()
      } catch (error) {
        this.showError({ error, message: 'Erro ao adicionar pagamento' })
      } finally {
        this.waitPeripheral = false
      }
    },

    async onDeleteMethodPayment(index) {
      try {
        const isBlocked = await this.stValidatePayBoxActionIsBlocked({
          action: this.uiPayBoxActionsEnum.PAYMENT_REMOVE,
        })
        if (isBlocked) {
          this.showWarning({ message: this.$t('Ação bloqueada no momento.') })
          return
        }

        const confirm = await this.confirm({
          text: this.$t('Deseja realmente cancelar este recebimento ?'),
          focusCancel: true,
        })
        if (confirm) {
          const receipt = this.paymentForm.receipts[index]

          const tefPaymentCancelation = await this.cancelTefPayment(receipt)
          if (tefPaymentCancelation || !isTefCard(receipt)) {
            this.stRemoveReceipt({ index, tefPaymentCancelation })
          }
        }
      } catch (error) {
        this.showError({ error })
      }
    },

    paymentShortcut(e) {
      const filterPayment = p => this.paymentMethodsInPdv.filter(m => m.method === p)[0]
      const actionsF = {
        [this.keyCode.f1]: () => this.onShowModalValue(filterPayment(this.paymentTypeEnum.CASH)),
        [this.keyCode.f2]: () =>
          this.onShowModalValue(filterPayment(this.paymentTypeEnum.CREDIT_CARD)),
        [this.keyCode.f3]: () =>
          this.onShowModalValue(filterPayment(this.paymentTypeEnum.DEBIT_CARD)),
        [this.keyCode.f4]: () => this.onShowModalValue(filterPayment(this.paymentTypeEnum.PIX)),
      }

      if (actionsF[e.code]) {
        e.preventDefault()
        actionsF[e.code]()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-weight: 600;
}
.method-buttons {
  row-gap: 10px;
}
</style>
