var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-value",
        title: _vm.titleModal,
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "hide-header-close": "",
        "hide-footer": "",
        scrollable: "",
        centered: "",
        size: "lg",
      },
      on: { hidden: _vm.resetModal },
    },
    [
      _c(
        "FormulateForm",
        {
          ref: "formReceiveValue",
          attrs: { name: "formReceiveValue" },
          on: { submit: _vm.onConfirm },
        },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center" },
                      [
                        _c("FormulateInput", {
                          staticClass: "mr-1 input-size",
                          attrs: {
                            id: "modal_value-input_value",
                            name: "valueReceive",
                            label: _vm.$t("Valor recebido"),
                            type: "text-number",
                            currency: "R$",
                            precision: 2,
                            validation: "required|min:0.01",
                          },
                          on: { input: _vm.onUpdateValue },
                          model: {
                            value: _vm.form.value,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "value", $$v)
                            },
                            expression: "form.value",
                          },
                        }),
                        _c(
                          "b-button",
                          {
                            staticClass: "p-1",
                            attrs: {
                              id: "modal_value-btn_reset_value",
                              variant: "danger",
                            },
                            on: { click: _vm.onResetValue },
                          },
                          [
                            _c("feather-icon", {
                              attrs: { icon: "DeleteIcon", size: "20" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-between align-items-end",
                      },
                      [
                        _c("p", { staticClass: "h5" }, [
                          _vm._v(" Saldo restante: "),
                        ]),
                        _c("p", { staticClass: "h5" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("currency")(_vm.balanceValueUpdated)
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    ),
                    _vm.change > 0
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between align-items-end",
                          },
                          [
                            _c("p", { staticClass: "h5" }, [
                              _vm._v(" Troco: "),
                            ]),
                            _c("p", { staticClass: "h5" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("currency")(_vm.change)) +
                                  " "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _vm.hasPaymentMethodInterfaces
                ? [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "method-interface",
                                type: "radio",
                                options: _vm.paymentMethodInterfacesOption,
                                label: _vm.$t("Pagar com"),
                                "element-class": ["d-flex", "mt-1"],
                              },
                              model: {
                                value: _vm.form.paymentMethodInterface,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "paymentMethodInterface",
                                    $$v
                                  )
                                },
                                expression: "form.paymentMethodInterface",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.isPOSMethod
                      ? _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { md: "12" } },
                              [
                                _c("FormulateInput", {
                                  staticClass: "required",
                                  attrs: {
                                    id: "modal_value-input_nsu",
                                    name: "nsu",
                                    type: "number",
                                    validation: "required",
                                    placeholder: _vm.$t("0000000"),
                                    label: _vm.$t(
                                      "Código de Autorização (NSU)"
                                    ),
                                  },
                                  model: {
                                    value: _vm.form.nsu,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "nsu", $$v)
                                    },
                                    expression: "form.nsu",
                                  },
                                }),
                              ],
                              1
                            ),
                            !_vm.isPixMethod
                              ? _c(
                                  "b-col",
                                  { attrs: { md: "12" } },
                                  [
                                    _c("FormulateInput", {
                                      staticClass: "required",
                                      attrs: {
                                        id: "modal_value-input_card_brand",
                                        label: _vm.$t("Bandeira do cartão"),
                                        validation: "required",
                                        name: "cardBrand",
                                        type: "e-radio-icon",
                                        "combo-options": _vm.cardBrands(),
                                      },
                                      model: {
                                        value: _vm.form.cardBrand,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "cardBrand", $$v)
                                        },
                                        expression: "form.cardBrand",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isManualPixMethod
                      ? _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c("FormulateInput", {
                                  directives: [
                                    {
                                      name: "mask",
                                      rawName: "v-mask",
                                      value: [
                                        "###.###.###-##",
                                        "##.###.###/####-##",
                                      ],
                                      expression:
                                        "['###.###.###-##', '##.###.###/####-##']",
                                    },
                                  ],
                                  attrs: {
                                    id: "modal_value-input_document_titular",
                                    name: "documentTitular",
                                    type: "text",
                                    validation: "optional|cpfCnpj",
                                    "validation-messages": {
                                      cpfCnpj: _vm.$t("Documento inválido"),
                                    },
                                    placeholder: _vm.$t("Documento"),
                                    label: _vm.$t(
                                      "Documento do titular (CPF ou CNPJ)"
                                    ),
                                  },
                                  model: {
                                    value: _vm.form.documentTitular,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "documentTitular", $$v)
                                    },
                                    expression: "form.documentTitular",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
              _c(
                "b-row",
                { staticClass: "mt-1" },
                [
                  _c(
                    "b-col",
                    { staticClass: "d-flex justify-content-end" },
                    [
                      _c("e-button", {
                        staticClass: "mr-1",
                        attrs: {
                          id: "modal_value-btn_cancel",
                          variant: "danger",
                          text: _vm.$t("Cancelar"),
                          "text-shortcuts": ["ESC"],
                        },
                        on: { click: _vm.hideModal },
                      }),
                      _c("e-button", {
                        attrs: {
                          id: "modal_value-btn_confirm",
                          variant: "primary",
                          text: "Confirmar",
                          "text-shortcuts": ["CTRL", "ENTER"],
                          busy: _vm.isBusy,
                        },
                        on: { click: _vm.onConfirm },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }